<template>
    <div id="CargoPanel">
      <feature-header name="Cargo" :score="vehicle.Cargo_Score" />
      <BasicFeaturePanel :features="features" icon-name="cargo_icon.svg"/>
    </div>
  </template>
  
  <script>
  const BasicFeaturePanel = () => import("Components/VehicleDetail/BasicFeaturePanel");
  const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
  export default {
    props: {
      vehicle: Object,
    },
    computed: {
      features() {
        const storageCapacity = this.vehicle.Storage_Capacity ? `${this.vehicle.Storage_Capacity} cu. ft.` : 'N/A';
        const storageCapacitySeatsFolded = this.vehicle.Storage_Seats_Folded ? `${this.vehicle.Storage_Seats_Folded} cu. ft.` : 'N/A';
        return [
          { label: "Storage capacity", value: storageCapacity },
          {
            label: "Storage with seats folded",
            value: storageCapacitySeatsFolded,
          },
        ];
      },
    },
    components: {
      FeatureHeader,
      BasicFeaturePanel
  },
  };
  </script>
  